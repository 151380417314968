/*  ==========================================================================
    COLORS
    List all colors concerning your project here
    ========================================================================== */

$white: #FFF;
$black: #000;
$gray: #eaeaea;
$font: #3C3C3C;
$orange: #EE7203;
$scrollbar: #ae173b;
$scrollbg: #ce8395;