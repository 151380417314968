/*  ==========================================================================
    BUTTONS
    ========================================================================== */

.besmart {
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 1px;
  span {
    text-decoration: underline;
  }
}
.btn {
  color: $white;
  background: $orange;
  border: 1px solid $orange;
  display: inline-block;
  font-weight: bold;
  width: 130px;
  text-align: center;
  padding: 14px 0;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 16px;
  transition-duration: .3s;
  text-decoration: none!important;
  @media only screen and (max-width: 1000px) {
    width: 110px;
    padding: 12px 0;
    font-size: 12px;
  }
  &:hover {
    background: $white;
    border-color: $white;
    color: $orange;
  }
}
