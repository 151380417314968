/*  ==========================================================================
    STD GALLERY
    ========================================================================== */

.std-gallery{
  background: $font;
  position: relative;
  padding-bottom: 60px;
  &>div {
    position: relative;
  }
  .name {
    color: $white;
    text-transform: uppercase;
    padding: 70px 0;
    font-size: 16px;
    line-height: 14px;
    margin: 0;
    @media only screen and (max-width: 1500px) {
      padding: 35px 0;
    }
  }
  .right {
    float: right;
    width: 420px;
    color: $white;
    .bodytext {
      @media only screen and (min-width: 900px) {
        margin-top: 45px;
      }
      a {
        text-decoration: underline;
        color: $white;
        transition-duration: .3s;
        &:hover {
          color: $orange;
        }
      }
    }
    @media only screen and (max-width: 1500px) {
      float: none;
      text-align: center;
      width: 100%;
      margin: 0;
      padding: 0 115px;
    }
    @media only screen and (max-width: 1000px) {
      margin-top: 0;
      font-size: 14px;
    }
    @media only screen and (max-width: 768px) {
      padding: 0 15px;
    }
  }
  .left {
    max-width: 1180px;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    @media only screen and (max-width: 1850px) {
      height: 660px;
      width: calc(100% - 550px)!important;
    }
    @media only screen and (max-width: 1500px){
      width: calc(100% - 75px)!important;
      max-width: inherit;
      height: auto;
      .item {
        float: left!important;
      }
    }
    @media only screen and (max-width: 768px) {
      width: 100%!important;
    }
    img {
      width: 100%;
      height: auto;
      @media only screen and (max-width: 1850px) {
        height: 100%;
        width: auto;
      }
      @media only screen and (max-width: 1600px){
        width: 100%;
        height: auto;
      }
    }
    .lazyload--progressive {
      opacity: 1;
      filter: blur(30px);
      transform: scale(1.1);
      transition: transform .5s;
    }
    .lazyloaded {
      opacity: 1;
      transition: opacity .5s;
    }
    .lazyload--progressive.lazyloaded {
      animation: blureffect .5s both;
      filter: blur(20px);
      transform: scale(1);
    }
    .item {
      float: left;
    }
  }
  blockquote {
    margin: 0;
    text-transform: lowercase;
    @media only screen and (max-width: 1000px) {
      font-size: 40px!important;
      line-height: 1!important;
      margin: 30px 0;
    }
    @media only screen and (max-width: 500px) {
      font-size: 35px!important;
    }
  }
  .h1 {
    color: $white;
    font-size: 80px;
    text-align: left;
    line-height: 90px;
    font-family: Quicksand;
    font-weight: bold;
    text-transform: lowercase;
    @media only screen and (max-width: 1600px){
      font-size: 60px;
      text-align: center;
    }
  }
  .h2 {
    color: $orange;
    font-size: 26px;
    line-height: 45px;
    font-weight: normal;
    margin-top: 10px;
    @media only screen and (max-width: 900px) {
      line-height: 1;
    }
  }
  .button {
    color: $white;
    text-decoration: underline;
    position: absolute;
    bottom: 20px;
    line-height: 1;
    transition-duration: .3s;
    &:hover {
      color: $orange;
    }
    @media only screen and (max-width: 1500px){
      position: relative;
      text-align: center;
      display: block;
      margin-top: 50px;
    }
  }
  .slick-arrow {
    position: absolute;
    right: -110px;
    color: $white;
    opacity: .6;
    transition-duration: .3s;
    font-size: 70px;
    cursor: pointer;
    z-index: 1;
    @media only screen and (max-width: 1850px) {
      right: -85px;
    }
    @media only screen and (max-width: 1500px){
      right: -65px;
    }
    @media only screen and (max-width: 768px) {
      right: 0;
      font-size: 30px;
      color: $white;
      border: 2px solid $white;
      padding: 4px 15px;
      font-weight: bold;
    }
    &.slick-prev {
      top: 40%;
      @media only screen and (max-width: 768px) {
        top: calc(60% - 55px);
      }
    }
    &.slick-next {
      top: 60%;
    }
    &:hover {
      opacity: 1;
    }
  }
}

.gallery-slider {
  position: relative;
  .item {
    display: inline-block;
  }
  img {
    width: 100%;
    height: auto;
  }
  .slick-arrow {
    position: absolute;
    right: 100px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
    font-size: 55px;
    color: hsla(0,0%,100%,.5);
    cursor: pointer;
    transition-duration: .3s;
    font-weight: 700;
  }
  .slick-arrow.slick-next {
    right: auto;
    left: 100px;
  }
  .slick-arrow:hover {
    color: #fff;
  }
}

/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
@media only screen and (max-width: 768px) {
  .gallery-slider {
    .slick-arrow {
      right: 20px;
    }
    .slick-arrow.slick-next {
      left: 20px;
    }
  }
}