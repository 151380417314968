/*  ==========================================================================
    MODULES
    Collection of all modules (except those which should be integrated 'inline')
    ========================================================================== */

// std gallery
@import 'lib/std-gallery';
@import 'lib/std-slogan';
@import 'lib/std-linkbox';
@import 'lib/std-textpic';
@import 'lib/std-togglebox';
@import 'lib/std-videos';
@import 'lib/std-socialmedia';
@import 'lib/ehot-offers';
@import 'lib/ehot-rooms';
@import 'lib/ehot-pricelist';
@import 'lib/ehot-forms';
@import 'lib/cc-gallery';
@import 'lib/rss-news';
