@import url("https://cdn.curator.io/3.0/css/curator.css");
#curator-feed-montivas {
  font-size: 1.6em;
  .cssload-box-loading {
    padding-bottom: 6em;
  }
  .crt-filter,
  .crt-load-more {
    display: none;
  }
  &.crt-feed-branded {
     .cssload-box-loading {
       display: none;
     }
    .crt-filter,
    .crt-load-more {
      display: block;
    }
  }
  .crt-filter .crt-filter-networks ul,
  .crt-filter .crt-filter-sources ul {
    background-color: transparent;
    .crt-filter-label {
      display: none!important;
    }
    li.active a,
    li.active a,
    li a.active,
    li a:hover {
      color: $orange;
      background-color: transparent;
    }
    li:nth-child(2) a {
      font-size: 30px;
    }
    li a {
      font-size: 0;
      text-transform: uppercase;
      transition-duration: .5s;
      color: rgba($font,.5);
      padding: 0 15px;
      i {
        font-size: 30px;
        transition-duration: .5s;
      }
    }
  }
}