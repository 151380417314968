/*  ==========================================================================
    GENERAL
    Useful global helpers

    INFO:
    - use it with care, its not a trash can!
    - contributions for the kickstarter are welcome!
    ========================================================================== */

// clearfix
@mixin clearfix() {
    *zoom: 1;
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// reset default button reset
@mixin resetHTMLButton() {
    appearance: none;
    background: none;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
    &:focus {
        outline: none;
    }
}

// responsive image
@mixin responsiveImg() {
    display: block;
    height: auto;
    width: 100%;
}

// cover image
@mixin coverImg() {
    display: block;
    height: auto;
    left: 50%;
    max-height: none;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
}

// cover image reset
@mixin coverImgReset() {
    display: initial;
    left: auto;
    max-height: 100%;
    max-width: 100%;
    min-height: 0;
    min-width: 0;
    position: static;
    top: auto;
    transform: translate(0, 0);
}

// create table
@mixin createTable() {
    display: table;
    > * {
        display: table-row;
        height: 100%;
        width: 100%;
        > * {
            display: table-cell;
            vertical-align: middle;
        }
    }
}

// reset table
@mixin resetTable() {
    display: initial;
    > * {
        display: initial;
        height: auto;
        width: auto;
        > * {
            display: initial;
            vertical-align: initial;
        }
    }
}

// transforms hex color to rgba
@mixin hex2rgba($color, $opacity: 0.7) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}
.mt1 {
    margin-top: 60px;
    @media only screen and (max-width: 768px) {
        margin-top: 35px;
    }
}
.mt2 {
    margin-top: 120px;
    @media only screen and (max-width: 768px) {
        margin-top: 35px;
    }
}
.mt3 {
    margin-top: 30px;
    @media only screen and (max-width: 768px) {
        margin-top: 35px;
    }
}
.mb1 {
    margin-bottom: 60px;
    @media only screen and (max-width: 768px) {
        margin-bottom: 35px;
    }
}
.mb2 {
    margin-bottom: 120px;
    @media only screen and (max-width: 768px) {
        margin-bottom: 35px;
    }
}
.mb3 {
    margin-bottom: 30px;
    @media only screen and (max-width: 768px) {
        margin-bottom: 35px;
    }
}
.pt1 {
    padding-top: 60px;
    @media only screen and (max-width: 768px) {
        padding-top: 35px;
    }
}
.pt2 {
    padding-top: 120px;
    @media only screen and (max-width: 768px) {
        padding-top: 35px;
    }
}
.pt3 {
    padding-top: 30px;
    @media only screen and (max-width: 768px) {
        padding-top: 35px;
    }
}
.pb1 {
    padding-bottom: 60px;
    @media only screen and (max-width: 768px) {
        padding-bottom: 35px;
    }
}
.pb2 {
    padding-bottom: 120px;
    @media only screen and (max-width: 768px) {
        padding-bottom: 35px;
    }
}
.pb3 {
    padding-bottom: 30px;
    @media only screen and (max-width: 768px) {
        padding-bottom: 35px;
    }
}
.table {
    display: table;
    width: 100%;
    height: 100%;
    position: relative;
    &>div {
        display: table-row;
        width: 100%;
        height: 100%;
        &>div {
            display: table-cell;
            vertical-align: middle;
            width: 100%;
            height: 100%;
        }
    }
}
.cssload-box-loading {
    width: 49px;
    height: 49px;
    margin: auto;
    position: relative;
}
.cssload-box-loading:before {
    content: '';
    width: 49px;
    height: 5px;
    background: rgb(60,60,60);
    opacity: 0.1;
    position: absolute;
    top: 58px;
    left: 0;
    border-radius: 50%;
    animation: shadow 0.58s linear infinite;
    @keyframes shadow {
        0% {
            transform: scale(1, 1);
        }
        50% {
            transform: scale(1.2, 1);
        }
        100% {
            transform: scale(1, 1);
        }
    }
}
.cssload-box-loading:after {
    content: '';
    width: 49px;
    height: 49px;
    background: rgb(238,114,3);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    animation: cssload-animate 0.58s linear infinite;
    @keyframes cssload-animate {
        17% {
            border-bottom-right-radius: 3px;
        }
        25% {
            transform: translateY(9px) rotate(22.5deg);
        }
        50% {
            transform: translateY(18px) scale(1, 0.9) rotate(45deg);
            border-bottom-right-radius: 39px;
        }
        75% {
            transform: translateY(9px) rotate(67.5deg);
        }
        100% {
            transform: translateY(0) rotate(90deg);
        }
    }
}
.bodytext {
    margin-top: 25px;
    li {
        list-style-type: none;
        position: relative;
        list-style-position: inside;
        &:before {
            content: "\e90a";
            font-family: icomoon;
            position: absolute;
            font-size: 10px;
            margin-left: -15px;
            top: 1px;
        }
    }
}