/*CUSTOM-COLOR*/
.element .eHotelier.form #dsgvo_newsletter .bn__form-grid__button,
.element .eHotelier.form #dsgvo_request .bn__form-grid__button,
.eHotelier.form #dsgvo_quickrequest .bn__form-grid__button {
  border-radius: 0;
}

.eHotelier.form {
  .bn-grid {
    @media only screen and (max-width: 1500px) {
      max-width: inherit;
      margin: auto 20px;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  #dsgvo_newsletter {
    h2 {
      color: $font;
      text-transform: uppercase;
      @media only screen and (max-width: 768px) {
        text-align: center;
      }
    }

    form {
      padding: 0;
      margin: 60px -10px;
      @media only screen and (max-width: 1500px) {
        margin: 30px 0;
      }
    }

    .bn__custom-color__background_button {
      background-color: transparent; /*color1*/
      border: 1px solid $white;
      color: $white;
    }

    .bn__form-grid {
      color: $white; /*color2*/
      .bn__form-grid__block {
        border: none;
        margin: 0;
        padding: 0 !important;
      }
    }

    input,
    select {
      border-bottom: 1px solid $white; /*color2*/
    }

    .bn__custom-color__background {
      background-color: $orange; /*color3*/
      border: 1px solid $white;
      color: $white;
      max-width: 300px;
    }

    .bn__form-grid.bn__form-grid--horizontal .bn__form-grid__row {
      width: 50%;

      &.bn__form-grid__row--horizontal-sm-half {
        width: 100%;

        & > div:last-child {
          position: relative;
          left: inherit;
          right: inherit;
          top: inherit;
          bottom: inherit;
          float: right;
          margin-top: 20px;
        }
      }
    }

    .consent_marketing {
      margin-top: 20px;
    }

    fieldset.bn__form-grid__block .bn__form-grid__row:last-child {
      display: none;
    }

    .btn {
      &:hover {
        color: $font;
        background: transparent;
        border-color: $font;
      }

      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

  /*CUSTOM-COLOR*/
  #dsgvo_request {
    .bn__custom-color__background {
      background-color: $orange; /*color1*/
    }

    .bn__custom-color__font {
      color: $font; /*color1*/
    }

    input:focus,
    select:focus {
      border-bottom: 1px solid $orange; /*color1*/
    }

    textarea:focus {
      border: 1px solid $orange; /*color1*/
    }

    .bn__form-grid__button {
      background-color: $orange; /*color2*/
    }
  }

  .btn {
    border-color: $white;
    width: 200px;
    line-height: 1;
    cursor: pointer;
  }

  &.quick {
    background: $orange;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 85px;
    display: none;
    z-index: 3;
    box-shadow: 0px -1px 20px 0px rgba(0, 0, 0, 0.5);
    @media only screen and (max-width: 1200px) {
      display: none !important;
    }

    .buttons {
      width: 17%;
      @media only screen and (max-width: 1850px) {
        width: 270px
      }
      text-align: center;

      .btn {
        width: 130px;
        color: $white;
        border-color: $white;
        margin: 5px auto;

        & + .btn {
          margin-left: 10px;
        }

        &:hover {
          background: transparent;
          color: $font;
          border-color: $font;
        }
      }

      a.btn {
        background: $white;
        color: $font;

        &:hover {
          background: $font;
          color: $white;
        }
      }
    }

    /*CUSTOM-COLOR*/
    #dsgvo_quickrequest {
      width: auto;

      form {
        width: auto;
        @media only screen and (max-width: 1850px) {
          margin-left: -20px;
        }
      }

      .bn__custom-color__background {
        background-color: $orange; /*color1*/
        &.consent_text.consent_text_marketing {
          border: 1px solid;
        }
      }

      .bn__custom-color__background_button {
        background-color: $orange; /*color3*/
        border: 1px solid $font;
        color: $font;
        max-width: 300px;
      }

      .bn__form-grid {
        &.bn__form-grid--horizontal {
          .bn__form-grid__block--horizontal-2 {
            width: 21.666667%;
          }

          .bn__form-grid__block--horizontal-3 {
            width: 13%;

            .consent_wrapper {
              width: auto;
              padding: 10px 5px;
            }

            .submit_form {
              display: none;
            }
          }
        }

        .bn__form-grid__column .bn__form-grid__column__controls .bn__form-grid__column__controls__item {
          background: $white;
          border-radius: 0;
          color: $orange;
        }
      }

      input,
      select {
        border-bottom: 1px solid $white; /*color3*/
      }
    }

    .cssload-box-loading {
      width: 40px;
      height: 40px;
      position: absolute;
      left: 0;
      right: 0;
      top: 10px;

      &:after {
        content: "";
        width: 40px;
        height: 40px;
        background: $white;
      }
    }
  }

  #dsgvo_quickrequest .bn__form-grid .bn__form-grid__column .bn__form-grid__column__overlay {
    pointer-events: none;
  }

  #dsgvo_request_nameprefix,
  #dsgvo_newsletter_nameprefix,
  #dsgvo_quickrequest_nameprefix {
    option[value="2"] {
      display: none;
    }
  }

  .bn__form-grid__room__child-ages,
  .bn__form-grid__block__child-ages {
    display: none;

    option[value="12"],
    option[value="13"],
    option[value="14"],
    option[value="15"],
    option[value="16"],
    option[value="17"] {
      display: none;
    }
  }
}

#uid40 .eHotelier.quick {
  display: none !important;
}

.ehot-form-newsletter {
  .ehot-form {
    &__main {
      display: flex;
      padding-top: 15px;
      color: white;
    }

    &__input {
      input, select {
        width: 100%;
        background-color: transparent;
        border: none;
        border-bottom: 2px solid white;
        font-size: 18px;
        padding: 5px;
        color: white;
        height: 34px;
        option {
          color: black;
        }
      }

      flex: 1;
      padding: 0 4px;
      position: relative;

      input {
        &:focus + label, &:not(:placeholder-shown) + label {
          font-size: 14px;
          bottom: 25px;
          left: 8px;
        }
      }

      select + label {
        font-size: 14px;
        bottom: 25px;
      }

      label {
        position: absolute;
        left: 10px;
        font-size: 18px;
        bottom: 5px;
        transition: 0.3s;
        pointer-events: none;
      }
    }

    input:placeholder-shown {
      background: red !important;
    }

    &__consent {
      color: white;
      display: flex;
      align-items: center;

      input {
        margin-right: 5px;
        font-size: 22px;
      }
      label {
        color: white;
        line-height: 1;
      }
    }
    &__consents {
      margin: 15px 5px 20px;
      position: relative;
      display: inline-block;
      &:not(:hover){
        > :nth-child(2) {
          display: none;
        }
      }
    }
    &__popup {
      line-height: 1.1;
      position: absolute;
      background-color: #ee7203;
      width: 100%;
      max-width: 450px;
      z-index: 1;
      color: white;
      font-size: 12px;
      padding: 10px;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
      bottom: 100%;
      a {
        text-decoration: none;
        &:hover {
          color: white !important;
        }
      }
    }
  }
  button {
    border: 2px solid white;
    padding: 12px 24px;
    color: white;
    text-transform: uppercase;
    background-color: transparent;
    font-weight: bold;
    font-family: inherit;
    transition: background-color 0.3s;
    cursor: pointer;
    margin-top: 5px;
    &:hover, &:focus {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  legend {
    font-size: 20px;
    margin: 10px 5px;
    color: white;
  }
}

