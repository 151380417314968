.slogan-element {
  position: relative;
  overflow: hidden;
  &:hover {
    .overflow{
      background: rgba(0,0,0,.2);
    }
    img {
      transform: scale(1.1);
    }
  }
  .content {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    color: $white;
    z-index: 2;
    @media only screen and (max-width: 1850px) {
      right: 20px;
      left: 20px;
    }
  }
  .slogan {
    font-size: 90px;
    font-weight: bold;
    font-family: 'Quicksand';
    text-transform: lowercase;
    margin: 0 5%;
    text-shadow: 2px 2px rgba(0,0,0,.4);
    @media only screen and (max-width: 1500px) {
      font-size: 60px;
    }
    @media only screen and (max-width: 1000px) {
      font-size: 40px;
      line-height: 1;
    }
    @media only screen and (max-width: 500px) {
      margin-top: 28px
    }
  }
  .subtitle {
    font-size: 26px;
    @media only screen and (max-width: 1000px) {
      margin-top: 25px;
    }
    @media only screen and (max-width: 500px) {
      font-size: 18px;
      line-height: 1;
    }
  }
  .bodytext {
    display: none;
    font-size: 16px;
    line-height: 30px;
    margin: auto;
    max-width: 1180px;
    @media only screen and (max-width: 1000px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 500px) {
      font-size: 12px;
      line-height: 2;
    }
    a {
      color: $white;
      text-decoration: underline;
      &:hover {
        color: $orange;
      }
    }
    ul {
      list-style-position: inside;
    }
  }
  .overflow {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    transition-duration: .3s;
    background: rgba(0,0,0,.1);
    z-index: 1;
  }
  .btn {
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    margin: auto;
    background: transparent;
    border-color: $white;
    cursor: pointer;
    z-index: 1;
    @media only screen and (max-width: 1200px) {
      bottom: 35px;
    }
    &:hover {
      border-color: $orange;
    }
  }
  img {
    width: 100%;
    height: auto;
    transition: transform 1s;
  }
  &.open {
    .content {
      @media only screen and (max-width: 1200px) {
        top: 40%;
      }
    }
    .overflow {
      background: rgba(0,0,0,.8);
      z-index: 1;
    }
    .bodytext {
      display: block;
      margin-top: 70px;
      margin-top: 30px;
    }
  }
}