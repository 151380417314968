.togglebox {
  .h1 {
    width: calc(100% - 70px);
  }
  .headline {
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
    &:hover .icon {
      font-weight: bold;
    }
  }
  .icon {
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(90deg);
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: $orange;
    font-size: 2em;
    transition-duration: .3s;
    &.act {
      transform: rotate(-90deg);
    }
  }
  .toggle {
    display: none;
    margin-top: 1em;
  }
}
@media only screen and (max-width: 768px) {
  .togglebox {
    .icon {
      height: 18px;
      line-height: 18px;
      font-size: 16px;
      width: 18px;
    }
    .h1 {
      width: calc(100% - 20px);
    }
  }
}

