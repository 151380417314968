.std-videos {
  overflow: hidden;
  iframe {
    width: 101%;
    border: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    @media only screen and (max-width: 1000px) {
      height: 500px;
    }
    @media only screen and (max-width: 500px) {
      height: 350px;
    }
  }
}