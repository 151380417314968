.linkbox {
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
  .box {
    text-align: center;
    display: flex;
    position: absolute;
    bottom: 80px;
    left: 0;
    right: 0;
    margin: auto;
    //flex-flow: row wrap;
    justify-content: space-around;
    max-width: 1460px;
    width: 100%;
    z-index: 1;
    @media only screen and (max-width: 1700px) {
      max-width: inherit;
      margin: auto;
      bottom: 25px;
    }
    a.block {
      width: 100%;
      display: block;
      font-family: Quicksand;
      font-weight: 400;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }
  .item {
    max-width: 350px;
    width: 100%;
    height: 350px;
    background: rgba($orange,.6);
    text-align: center;
    font-size: 4em;
    color: $white;
    transition-duration: .3s;
    @media only screen and (max-width: 1700px) {
      max-width: 260px;
      height: 260px;
      font-size: 2.7em;
      margin: auto;
    }
    @media only screen and (max-width: 1500px) {
      max-width: 220px;
      height: 220px;
      font-size: 2.7em;
      margin: auto;
    }
    @media only screen and (max-width: 1000px) {
      max-width: 165px;
      height: 165px;
      font-size: 27px;
    }
    @media only screen and (max-width: 768px) {
      width: 90px;
      height: 90px;
      font-size: 17px;
    }
    span {
      position: relative;
      display: inline-block;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 2px;
        left: 0;
        border-bottom: 2px solid $white;
        @media only screen and (max-width: 768px) {
          bottom: 0;
        }
      }
    }
    &:hover {
      background: $orange;
    }
  }
  .background {
    display: none;
    a.bg {
      pointer-events: none;
      cursor: default;
      @media only screen and (max-width: 768px) {
        pointer-events: initial;
        cursor: pointer;
      }
    }
    a.block {
      display: none;
    }
    @media only screen and (max-width: 768px) {
      display: block!important;
      position: relative;
      a.block {
        position: absolute;
        left: 15px;
        top: 15px;
        display: block;
        font-family: Quicksand;
      }
      &:nth-child(odd) a {
        left: inherit;
        right: 15px;
      }
    }
    &.element0 {
      display: block;
    }
  }
}