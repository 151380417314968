.roomlist {
  .cssload-box-loading {
    margin: 20px auto 30px;
  }
  .room {
    padding: 0;
    &>div {
      display: flex;
      justify-content: flex-start;
      padding: 120px 0;
      @media only screen and (max-width: 1850px) {
        margin: 0 60px;
      }
      @media only screen and (max-width: 1500px) {
        display: block;
        padding: 0;
        margin: 0;
        &>div {
          width: 100%!important;
        }
      }
      &>div {
        float: none;
        width: 100%;
        position: relative;
      }
    }
    .h1 {
      color: $orange;
      text-transform: uppercase;
      font-size: 28px;
      font-weight: bold;
      line-height: 45px;
      text-align: left;
      @media only screen and (max-width: 1500px) {
        padding-top: 35px;
        text-align: center;
      }
      @media only screen and (max-width: 768px) {
        font-size: 22px;
        line-height: 1;
      }
    }
    .size {
      margin-top: 10px;
      font-weight: bold;
    }
    .buttons {
      position: absolute;
      bottom: 50px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 1500px) {
        position: relative;
        bottom: inherit;
        left: inherit;
        right: inherit;
        margin: 50px auto;
        width: 420px;
      }
      @media only screen and (max-width: 768px) {
        width: 100%;
        max-width: 320px;
      }
      a.btn {
        background: $white;
        border: 1px solid;
        color: $font;
        @media only screen and (max-width: 768px) {
          width: 90px;
        }
        line-height: 1;
        &:hover {
          color: $orange;
        }
      }
      span.btn {
        cursor: pointer;
        line-height: 1;
        display: none;
        @media only screen and (max-width: 768px) {
          width: 90px;
        }
        &:hover {
          background: transparent;
          border-color: $orange;
          color: $orange;
        }
      }
    }
    .right {
      max-width: 650px;
      @media only screen and (max-width: 1500px) {
        max-width: inherit;
        margin: 30px auto;
      }
      &>div {
        width: 420px;
        position: relative;
        height: 100%;
        padding: 35px 0;
        float: right;
        @media only screen and (max-width: 1500px) {
          padding: 0;
          margin: auto;
          float: none!important;
          width: calc(100% - 40px);
        }
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
        &:before {
          content: "";
          border-top: 1px solid $font;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
        }
        &:after {
          content: "";
          border-top: 1px solid $font;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }
        .bodytext {
          margin-top: 25px;
          max-height: 360px;
          overflow-y: auto;
          @media only screen and (max-width: 1850px) {
            max-height: 320px;
          }
          @media only screen and (max-width: 768px) {
            max-height: initial;
          }
        }
      }
    }
    picture {
      display: block!important;
      img {
        @media only screen and (max-width: 1700px) {
          height: 100%;
          width: auto;
        }
        @media only screen and (max-width: 1500px) {
          width: 100%;
          height: auto;
        }
      }
    }
    .slick-arrow {
      left: -110px;
      right: inherit;
      @media only screen and (max-width: 1850px) {
        left: -85px;
      }
      @media only screen and (max-width: 1500px) {
        left: 60px;
        z-index: 1;
      }
      @media only screen and (max-width: 768px) {
        left: 0;
        top: 50%;
      }
      &.slick-prev {
        @media only screen and (max-width: 768px) {
          top: calc(50% - 55px);
        }
      }
    }
    &:nth-child(odd) {
      background: $white;
      .slick-arrow {
        color: $font;
        right: -110px;
        left: inherit;
        @media only screen and (max-width: 1850px) {
          left: -85px;
        }
        @media only screen and (max-width: 1500px) {
          left: inherit;
          right: 60px;
          color: $white;
        }
        @media only screen and (max-width: 768px) {
          right: 0;
          top: 50%;
        }
        &.slick-prev {
          @media only screen and (max-width: 768px) {
            top: calc(50% - 55px);
          }
        }
      }
      .right {
        color: $font;
      }
    }
    &:nth-child(even)+>div{
      flex-direction: row-reverse;
      .right>div {
        float: left;
      }
      .buttons {
        a.btn {
          background: transparent;
          border: 2px solid $white;
          color: $white;
          font-weight: bold;
          &:hover {
            color: $orange;
            border-color: $orange;
          }
        }
      }
    }
    &:nth-child(even) {
      .right>div {
        &:before {
          border-color: $white;
        }
        &:after {
          border-color: $white;
        }
      }
    }
  }
}