.pricelist_wrap::-webkit-scrollbar {
  width: 3em;
  height: 7px;
}
.pricelist_wrap::-webkit-scrollbar-thumb {
  background: #3c3c3c;
  border-radius: 0;
}
.pricelist_wrap::-webkit-scrollbar-track {
  background: #d46a0b;
}
.pricelist  {
  @media only screen and (max-width: 1000px) {
    font-size: 12px;
  }
  .information {
    padding: 0;
    .bn-grid_big {
      max-width: 1470px;
      @media only screen and (max-width: 1850px) {
        max-width: inherit;
        box-sizing: border-box;
      }
    }
    .price {
      background: $orange;
      color: $white;
      padding: 115px 0;
      @media only screen and (max-width: 768px) {
        padding: 55px 0;
      }
      h2 {
        color: $font;
        margin-bottom: 20px;
      }
      .subheader {
        padding: 10px 0 30px 0;
      }
      .pricelist_wrap {
        @media only screen and (max-width: 768px) {
          overflow-x: auto;
        }
      }
      table {
        width: 100%;
        text-transform: uppercase;
        border-spacing: 0;
        border-collapse: collapse;
        @media only screen and (max-width: 768px) {
          min-width: 1200px;
        }
        thead {
          td {
            padding: 10px 20px 30px 20px;
            position: relative;
            &:nth-child(1) {
              font-size: 28px;
              padding-left: 0;
            }
            &:after {
              content: "";
              border-right: 2px solid $white;
              position: absolute;
              right: 0;
              top: 0;
              bottom: 20px;
            }
          }
        }
        tbody {
          tr:nth-child(odd) {
            background: rgba($font,0.14);
          }
          td {
            padding: 10px 20px;
            border-right: 2px solid $white;
          }
        }
      }
      .buttons {
        margin-top: 85px;
        position: relative;
        @media only screen and (max-width: 768px) {
          margin-top: 45px;
          text-align: center;
        }
        .btn {
          background: $white;
          color: $font;
          border-color: $white;
          line-height: 1;
          &:hover {
            color: $white;
            background: $font;
            border-color: $font;
          }
        }
        a+a {
          margin-left: 20px;
          .btn {
            color: $white;
            background: transparent;
            &:hover {
              background: transparent;
              border-color: $font;
              color: $font;
            }
          }
        }
        span.special {
          position: absolute;
          right: 0;
          line-height: 45px;
          @media only screen and (max-width: 768px) {
            position: relative;
            right: inherit;
            display: block;
            margin-bottom: 30px;
            line-height: 1.5;
          }
        }
      }
    }
    .additional {
      background: $gray;
    }
    .other {
      background: $font;
      color: $white;
    }
    .headline {
      font-size: 26px;
      line-height: 40px;
      padding: 30px 0;
      position: relative;
      @media only screen and (max-width: 1000px) {
        font-size: 18px;
        line-height: 30px;
      }
      .icon {
        transform: rotate(90deg);
        display: inline-block;
        font-size: 53px;
        position: absolute;
        right: 15px;
        line-height: 50px;
        cursor: pointer;
        @media only screen and (max-width: 1000px) {
          font-size: 28px;
          line-height: 35px;
          right: 15px;
        }
        &.act {
          transform: rotate(-90deg);
        }
      }
    }
    .bodytext {
      display: none;
      margin-bottom: 50px;
    }
  }
}