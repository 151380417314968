.offer {
  .priceinfo {
    background: $font;
    width: 150px;
    padding: 33px 0;
    text-align: center;
    position: absolute;
    right: -81px;
    top: -155px;
    @media only screen and (max-width: 1000px) {
      width: 90px;
      height: 90px;
      padding: 18px 0;
      right: 0;
      top: -90px!important;
      font-size: 12px;
    }
    .price {
      display: block;
      font-size: 30px;
      @media only screen and (max-width: 1000px) {
        font-size: 21px;
        line-height: 0.5;
      }
    }
  }
  .cssload-box-loading {
    margin: 20px auto 30px auto;
  }
}
.offerslider {
  h2 {
    position: absolute;
    left: 70px;
    top: 55px;
    text-transform: uppercase;
    font-weight: 600;
    color: $font;
    font-size: 16px;
    line-height: 1;
    margin: 0;
    @media only screen and (max-width: 1850px) {
      left: 20px;
    }
    @media only screen and (max-width: 1000px) {
      top: 35px;
    }
  }
  h3 {
    margin-top: 30px;
    font-size: 22px;
    color: $white;
    text-align: left;
    line-height: 50px;
    font-family: Quicksand;
    @media only screen and (max-width: 768px) {
      margin: 15px 0;
      line-height: 1;
    }
  }
  .item {
    &>div{
      display: flex;
      @media only screen and (max-width: 768px) {
        display: block;
      }
      &>div {
        width: 100%;
        position: relative;
        &.right {
          height: 770px;
          @media only screen and (max-width: 1850px) {
            max-width: 740px;
          }
          @media only screen and (max-width: 1500px) {
            max-width: 450px;
          }
          @media only screen and (max-width: 1000px) {
            height: 550px;
          }
          @media only screen and (max-width: 768px) {
            height: 285px;
            margin: -50px -2% 0 auto;
          }
        }
        &.left {
          margin: 150px 0 70px 70px;
          color: $white;
          @media only screen and (max-width: 1850px) {
            margin-left: 20px;
          }
          @media only screen and (max-width: 1000px) {
            margin: 85px 0 20px 20px;
          }
          @media only screen and (max-width: 768px) {
            padding: 85px 15px 20px 15px;
            margin: 0;
          }
          &>div {
            background: $orange;
            position: absolute;
            left: 0;
            right: -70px;
            top: 0;
            bottom: 0;
            padding: 75px 150px;
            z-index: 1;
            @media only screen and (max-width: 1850px) {
              padding-left: 40px;
            }
            @media only screen and (max-width: 1000px) {
              right: -180px;
              padding: 35px 90px 35px 40px;
            }
            @media only screen and (max-width: 768px) {
              position: relative;
              top: inherit;
              bottom: inherit;
              left: inherit;
              right: inherit;
              height: 280px;
              box-sizing: border-box;
              padding: 25px 40px;
            }
          }
          .table {
            border-bottom: 2px solid $white;
            border-top: 2px solid $white;
            position: relative;
            &>div {
              &>div {
                vertical-align: top;
              }
            }
          }
          .bodytext {
            margin: 20px 0 0 0;
            a {
              text-decoration: underline;
              color: $white;
              transition-duration: .3s;
              &:hover {
                color: $font;
              }
            }
            @media only screen and (max-width: 768px) {
              display: none;
            }
          }
          .timerage {
            display: inline-block;
            color: $font;
            margin: 5px auto auto auto;
            font-weight: 600;
            @media only screen and (max-width: 1200px) {
              line-height: 1;
            }
            @media only screen and (max-width: 1000px) {
              margin: 5px auto 15px;
            }
            @media only screen and (max-width: 768px) {
              margin: 5px auto auto;
              display: block;
              &:before {
                content: initial!important;
              }
            }
            &+.timerage {
              @media only screen and (max-width: 768px) {
                margin: 0;
              }
              &:before {
                content: "|";
                padding: 0 5px;
              }
            }
          }
          .btn {
            padding: 6px 0;
            border-color: $white;
            color: $white;
            position: absolute;
            bottom: 50px;
            @media only screen and (max-width: 768px) {
              bottom: 25px;
            }
            &:hover {
              color: $font;
              border-color: $font;
              background: transparent;
            }
          }
        }
      }
      img {
        height: 100%;
        width: auto;
        @media only screen and (max-width: 1000px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .slick-arrow {
    position: absolute;
    right: 48%;
    color: $white;
    opacity: .3;
    transition-duration: .3s;
    font-size: 70px;
    cursor: pointer;
    z-index: 1;
    @media only screen and (max-width: 1850px) {
      right: 41%;
    }
    @media only screen and (max-width: 1700px) {
      right: 46%;
    }
    @media only screen and (max-width: 1500px) {
      right: 420px;
    }
    @media only screen and (max-width: 1000px) {
      right: 32%;
    }
    @media only screen and (max-width: 800px) {
      right: 30%;
    }
    @media only screen and (max-width: 768px) {
      right: 0;
      font-size: 30px;
      color: $font;
      border: 2px solid $font;
      padding: 4px 15px;
      font-weight: bold;
    }
    &.slick-prev {
      top: 40%;
    }
    &.slick-next {
      top: 53%;
      @media only screen and (max-width: 768px) {
        top: 30%;
      }
    }
    &:hover {
      opacity: 1;
    }
  }
}
.offerlist {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1800px;
  .item {
    width: calc(100% * (1/3));
    margin-top: 80px;
    padding-bottom: 55px;
    &.no_record {
      text-align: center;
    }
    @media only screen and (max-width: 1700px) {
      width: calc(100% * (1/2));
      &:nth-child(even) .offer_box {
        margin-right: 0;
      }
      &:nth-child(odd) .offer_box {
        margin-left: 0;
      }
    }
    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
    @media only screen and (max-width: 768px) {
      margin-top: 0;
    }
    .table>div>div {
      vertical-align: text-top;
    }
    .offer_box {
      margin: 10px;
      @media only screen and (max-width: 768px) {
        margin: 10px 0!important;
      }
      .content {
        height: 490px;
        max-height: 490px;
        padding: 70px 60px;
        transition-duration: .3s;
        @media only screen and (max-width: 768px) {
         text-align: center;
          height: auto;
          padding: 30px 0;
        }
        &>div {
          border-top: 2px solid;
          border-bottom: 2px solid;
          position: relative;
          height: 100%;
          padding: 20px 0 30px 0;
        }
        h2 {
          font-family: Quicksand;
          font-weight: bold;
          width: calc(100% - 170px);
          line-height: 37px;
          transition-duration: .3s;
          @media only screen and (max-width: 768px) {
            width: 100%;
          }
        }
        .btn {
          color: $font;
          background: transparent;
          border-color: $font;
          line-height: 1;
          position: absolute;
          bottom: 0;
          @media only screen and (max-width: 768px) {
            margin: 20px auto;
            position: relative;
          }
        }
      }
      .priceinfo {
        color: $white;
        top: -150px;
        right: 0;
        width: 130px;
        padding: 20px 0;
        background: $orange;
        @media only screen and (max-width: 1000px) {
          top: -110px!important;
          width: 90px;
          padding: 18px 0;
          line-height: 1.8;
        }
      }
      &:hover {
        background: $orange;
        color: $white;
        .content {
          .priceinfo {
            background: $font;
          }
          h2 {
            color: $white;
          }
          .btn {
            color: $white;
            border-color: $white;
          }
        }
        .timerage {
          color: $font;
        }
      }
    }
    .timerage {
      display: inline-block;
      @media only screen and (max-width: 768px) {
       display: block;
      }
      &+.timerage:before{
        content: "|";
        padding: 0 2px;
        @media only screen and (max-width: 768px) {
          content: initial;
        }
      }
    }
    .slick-slide div {
      height: 100%!important;
      display: block!important;
    }
    ul.slick-dots {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      text-align: center;
      li {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid $white;
        background: transparent;
        margin: 0 5px;
        border-radius: 20px;
        cursor: pointer;
        &:hover,
        &.slick-active {
          background: white;
        }
      }
    }
  }
  .offerdetail {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 100%;
    left: 0;
    z-index: 9999;
    overflow-y: scroll;
    background-color: transparent;
    padding: 25px;
    transition: background-color 1s,top 0s;
    transition-delay: 1s;
    @media only screen and (max-width: 768px) {
      padding: 0;
    }
    .detail_wrapper {
      transition: margin-top 1s;
      background: $orange;
      max-width: 1180px;
      margin: 100vh auto auto auto;
      @media only screen and (max-width: 1500px) {
        max-width: inherit;
        width: 80%;
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .detail_closer {
      position: absolute;
      right: -70px;
      color: $white;
      font-size: 3em;
      cursor: pointer;
      display: none;
      @media only screen and (max-width: 768px) {
        right: 15px;
        top: 10px;
      }
    }
    span.next_offer.icon-pfeil_2,
    span.prev_offer.icon-pfeil_1 {
      display: none;
    }
  }
  .detail_opened {
    .offerdetail {
      top: 0;
      background-color: rgba($font,.9);
      transition: background-color 1s,top 0s;
      transition-delay: 0s;
      .detail_wrapper {
        margin-top: 0;
        position: relative;
        .detail_closer {
          display: block;
        }
        .content {
          position: relative;
          padding: 50px 150px;
          box-sizing: border-box;
          .bodytext {
            padding: 25px 0;
            box-sizing: border-box;
            position: relative;
            .text {
              padding: 30px 0;
            }
            &:before {
              content: "";
              border-top: 2px solid $white;
              width: 100%;
              position: absolute;
              top: 0;
            }
            &:after {
              content: "";
              border-bottom: 2px solid $white;
              width: 100%;
              position: absolute;
              bottom: 0;
            }
            .buttons {
              margin: 20px 0;
              @media only screen and (max-width: 768px) {
                text-align: center;
              }
              .btn {
                color: $white;
                border-color: $white;
                line-height: 1;
                &:hover {
                  background: transparent;
                  border-color: $font;
                  color: $font;
                }
                &:first-child {
                  background: $white;
                  color: $font;
                  &:hover {
                    background: $font;
                    color: $white;
                  }
                }
                &+.btn {
                  margin-left: 10px;
                }
              }
            }
            .additional {
              margin-bottom: 2.5em;
              .headline {
                font-size: 16px;
                line-height: 40px;
                padding: 8px 30px;
                position: relative;
                color: $white;
                background: rgba($font,.15);
              }
              .icon {
                transform: rotate(90deg);
                display: inline-block;
                font-size: 33px;
                position: absolute;
                right: 40px;
                line-height: 40px;
                cursor: pointer;
                font-weight: bold;
                &.act {
                  transform: rotate(-90deg);
                }
              }
              .desc {
                display: none;
                margin-top: 1em;
              }
            }
          }
          h2 {
            color: $white;
            font-family: Quicksand;
            font-weight: 500;
            @media only screen and (max-width: 768px) {
              padding: 20px 0;
            }
          }
          li:before {
            font-weight: bold;
          }
          @media only screen and (max-width: 1000px) {
            padding: 45px 30px;
          }
        }
        .prices {
          background: $font;
          color: $white;
          width: 170px;
          padding: 48px 0;
          text-align: center;
          position: absolute;
          right: 120px;
          top: -100px;
          @media only screen and (max-width: 1000px) {
            width: 100px;
            padding: 20px 0;
            font-size: 12px;
          }
          @media only screen and (max-width: 768px) {
            top: -115px;
            left: 15px;
          }
          .price {
            display: block;
            font-size: 30px;
            @media only screen and (max-width: 1000px) {
              font-size: 24px;
            }
          }
        }
      }
      .subtitle {
        color: $white;
      }
    }
    span.next_offer.icon-pfeil_2,
    span.prev_offer.icon-pfeil_1 {
      display: block;
      position: absolute;
      right: -55px;
      font-size: 55px;
      color: rgba($white,.5);
      top: 37%;
      cursor: pointer;
      &:hover {
        color: $white;
      }
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    span.prev_offer.icon-pfeil_1 {
      top: calc(37% - 90px);
    }
  }
  img {
    width: 100%;
    height: auto;
  }
}