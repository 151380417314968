.std-textpic {
  display: flex;
  @media only screen and (max-width: 1500px) {
    display: block;
  }
  .box {
    width: 100%;
    overflow: hidden;
    @media only screen and (max-width: 1500px) {
      margin: 0 0 35px 0;
    }
    blockquote {
      margin: 0;
    }
    .h1 {
      color: $font;
      font-size: 80px;
      text-align: left;
      line-height: 90px;
      font-family: Quicksand;
      font-weight: bold;
      text-transform: lowercase;
      @media only screen and (max-width: 1500px) {
        font-size: 45px;
        font-weight: normal;
        text-align: center;
        line-height: 1;
      }
      @media only screen and (max-width: 768px) {
        font-size: 28px;
      }
    }
    .h2 {
      color: $orange;
      font-size: 26px;
      line-height: 45px;
      font-weight: 400;
      margin-top: 30px;
      @media only screen and (max-width: 1500px) {
        line-height: 1;
        text-align: center;
      }
      @media only screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
    .bodytext {
      font-size: 1.6em;
      margin-top: 45px;
      line-height: 30px;
      @media only screen and (max-width: 1850px) {
        margin-top: 0;
      }
      @media only screen and (max-width: 1500px) {
        text-align: center;
      }
      @media only screen and (max-width: 1000px) {
        font-size: 14px;
        margin-top: 25px;
        padding-top: 0!important;
      }
      &.first {
        padding: 3em 0 1em 0;
      }
      ul {
        padding-left: 1em;
        li {
          list-style-position: unset;
        }
      }
      a {
        text-decoration: underline;
        color: $font;
        transition-duration: .3s;
        &:hover {
          color: $orange;
        }
      }
    }
    .text {
      width: 66%;
      margin: 130px auto 70px auto;
      @media only screen and (max-width: 1850px) {
        margin-top: 70px;
      }
      @media only screen and (max-width: 1700px) {
        width: 100%;
        padding: 0 60px;
        box-sizing: border-box;
      }
      @media only screen and (max-width: 1500px) {
        margin: 0!important;
        padding: 0 115px;
      }
      @media only screen and (max-width: 768px) {
        padding: 0 30px;
      }
    }
  }
  img {
    width: 100%;
    height: auto;
  }
}
.rtl {
  &:nth-child(odd) {
    direction: rtl;
    .text {
      direction: ltr;
    }
  }
}