_:-ms-lang(x), .rss { display: none }

.rss {
  counter-reset: section;
  h2 {
    font-size: 16px;
    font-family: Muli;
    color: $font;
  }
  .rss-feed {
    width: calc(100% - 100px);
    @media only screen and (max-width: 1400px) {
      width: calc(100% - 55px);
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .item {
    position: relative;
    margin: 40px 0 0 0;
    .left {
      width: 345px;
      height: 100%;
      min-width: 345px;
      overflow: hidden;
      @media only screen and (max-width: 1400px) {
        display: none;
      }
      img {
        display: block;
        height: 100%;
        width: auto;
      }
    }
    .right {
      background: $orange;
      color: $white;
      padding: 75px 120px;
      border-left: 10px solid $white;
      font-size: 1.6em;
      line-height: 30px;
      @media only screen and (max-width: 1400px) {
        border: 0;
      }
      @media only screen and (max-width: 768px) {
        padding: 25px;
      }
      a {
        color: $white;
      }
      h3 {
        font-size: 23px;
        margin-bottom: 20px;
        color: $font;
      }
      .date {
        position: absolute;
        right: 25px;
        top: 15px;
        color: $font;
        @media only screen and (max-width: 768px) {
          position: relative;
          right: initial;
          top: initial;
          padding-bottom: 10px;;
        }
      }
      .count {
        position: absolute;
        right: 0;
        top: -40px;
        color: gray;
        z-index: 1;
        &:before {
          counter-increment: section;
          content: counter(section) " / ";
        }
      }
    }
  }
  .slick-arrow {
    position: absolute;
    right: -100px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    font-size: 65px;
    color: rgba($font,.3);
    cursor: pointer;
    transition-duration: .3s;
    @media only screen and (max-width: 1400px) {
      right: -50px;
    }
    @media only screen and (max-width: 768px) {
      right: -15px;
      border: 1px solid;
      padding: 7px 14px;
      font-size: 25px;
      color: $font;
      opacity: .6;
    }
    &:hover {
      opacity: 1;
    }
    &.slick-next{
      top: calc(50% + 70px);
      @media only screen and (max-width: 768px) {
        top: calc(50% + 60px);
        right: -15px;
        border: 1px solid;
        padding: 7px 14px;
        font-size: 25px;
      }
    }
  }
}