/*  ==========================================================================
    FOOTER
    Styling for the lowest part of the website
    ========================================================================== */

.footer {
  background: $font;
  color: $white;
  font-size: 1.6em;
  padding-bottom: 100px;
  @media only screen and (max-width: 1200px) {
    padding-bottom: 50px;
  }
  @media only screen and (max-width: 1000px) {
    font-size: 12px;
  }
  &>div {
    max-width: 1445px;
    margin: auto;
    padding: 60px 0;
    @media only screen and (max-width: 1500px) {
      max-width: inherit;
      padding: 60px 20px;
    }
    @media only screen and (max-width: 1000px) {
      padding: 60px 115px;
    }
    @media only screen and (max-width: 768px) {
      padding: 35px 15px;
    }
  }
  a {
    color: $white;
    transition-duration: .3s;
    &:hover {
      color: $orange;
    }
  }
  .flex {
    display: flex;
    width: 100%;
    @media only screen and (max-width: 1200px) {
      display: block;
    }
  }
  .half_footer {
    width: 50%;
    position: relative;
    @media only screen and (max-width: 1200px) {
      width: 100%;
      .webcam {
        margin-top: 35px;
        @media only screen and (max-width: 768px) {
          margin-top: 40px;
        }
      }
    }
    .half_footer {
      padding: 20px 0 0;
      @media only screen and (max-width: 1200px) {
        width: 50%;
        float: left;
      }
      @media only screen and (max-width: 768px) {
        width: 100%;
        float: none;
        text-align: center;
      }
      &:first-child {
        border-right: 2px solid $white;
        @media only screen and (max-width: 768px) {
          border-right: none;
          border-bottom: 2px solid $white;
          padding-bottom: 25px;
        }
      }
    }
  }
  .footer_top {
    .contakt {
      line-height: 30px;
      position: relative;
      a {
        text-decoration: underline;
      }
      .icon-standort {
        text-decoration: none;
        position: absolute;
        right: 15%;
        bottom: 8px;
        font-size: 2.8em;
        @media only screen and (max-width: 370px) {
          right: 10px;
        }
      }
    }
    .socialicon {
      text-align: center;
      font-size: 14px;
      @media only screen and (max-width: 768px) {
        margin: 20px auto;
      }
      a {
        font-size: 2em;
        margin: 0 20px 30px 20px;
        display: inline-block;
        @media only screen and (max-width: 768px) {
          margin: 0 20px;
        }
        &.icon-you_tube {
          display: block;
        }
      }
    }
    .vat {
      font-size: 12px;
      opacity: .4;
      margin-top: 15px;
    }
    .powered {
      font-size: 12px;
      position: relative;
      display: block;
      @media only screen and (max-width: 768px) {
        width: 80%;
        margin: auto;
      }
      .icon-lungolivigno {
        font-size: 110px;
        position: absolute;
        right: -160px;
        top: 0;
        line-height: 25px;
      }
    }

    img {
      height: auto;
      width: 80%;
      margin: auto;
      max-width: 350px;
      max-height: 100px;
      width: auto;
      margin-top: 10px;
      margin-left: -6px;
    }
    .weather {
      .cssload-box-loading {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
      .btn {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
        margin: auto;
        background: $white;
        border-color: $white;
        color: $font;
        transition-duration: .3s;
        &:hover {
          color: $orange;
        }
      }
      .weatherfooter {
        font-size: 35px;
        font-weight: bold;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        .weather-symbol {
          line-height: 150px;
          display: inline-flex;
          @media only screen and (max-width: 768px) {
            line-height: 0;
          }
        }
        .icon {
          font-size: 4em;
          @media only screen and (max-width: 768px) {
            font-size: 2em;
          }
          &+span {
            font-family: Quicksand;
            font-weight: bold;
          }
        }
      }
      .icon {

      }
      img {
        width: auto;
        height: auto;
      }
    }
  }
  .footer_bottom {
    margin-top: 40px;
    .legalmenu {
      display: inline-block;
      a {
        &:hover {
          text-decoration: underline;
        }
        &:after {
          content: "|";
          padding: 0 10px;
          color: $white!important;
        }
      }
    }
    .bn_link {
      float: right;
      font-size: 1em;
      > a {
        display: block;
        width: 7em;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1em;
    }
  }
  .job-links {
    text-align: center;
    a {
      font-size: 20px;
      font-weight: 700;
    }
  }
}
.mobildeqr.flex {
  @media only screen and (min-width: 1200px) {
    display: none!important;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 13px;
    text-align: center;
    display: flex;
    line-height: 50px;
    background: #57595E;
    text-transform: uppercase;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    &>div {
      width: 100%;
      &:last-child {
        background-color: $orange;
      }
      .flex {
        display: flex;
        a {
          width: 100%;
          border-right: 1px solid $white;
          .bn-icon.icon-wahats_app {
            font-size: 1.3em;
          }
        }
      }
    }
    a {
      color: $white;
    }
    &.act {
      position: fixed;
    }
  }
}

/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */