.category {
  text-align: center;
  font-size: 35px;
  margin: 1em 0;
  @media only screen and (max-width: 1000px) {
    font-size: 25px;
  }
  span {
    transition-duration: .5s;
    padding: 0 25px;
    cursor: pointer;
    color: rgba($font,.5);
    @media only screen and (max-width: 768px) {
      padding: 0 10px;
    }
    &:hover,
    &.act {
      color: $orange;
    }
    &.all {
      font-size: 30px;
      @media only screen and (max-width: 1000px) {
        font-size: 20px;
      }
    }
  }
}
.masonry {
  .btn {
    margin: auto;
    display: block;
    width: 200px;
    margin-top: 50px;
    cursor: pointer;
    background: transparent;
    color: $font;
    border-color: $font;
    &:hover {
      border-color: $orange;
      color: $orange;
    }
  }
  .bn-gallery__image {
    cursor: zoom-in;
    float: left;
    padding: 5px;
    width: 33.33%;
    > div {
      overflow: hidden;
    }
    img {
      display: block;
      height: auto;
      width: 100%;
    }
    .lazyload, .lazyloading {
      opacity: 0;
    }
    .lazyload--progressive {
      opacity: 1;
      filter: blur(30px);
      transform: scale(1.1);
      transition: transform .5s;
    }
    .lazyloaded {
      opacity: 1;
      transition: opacity .5s;
    }
    .lazyload--progressive.lazyloaded {
      animation: blureffect .5s both;
      filter: blur(20px);
      transform: scale(1);
      @keyframes blureffect {
        0% {filter: blur(20px);}
        100% {filter: blur(0);}
      }
    }
    @media only screen and (max-width: 1500px) {
      width: 50%;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .grid__item {
    margin-bottom: 2%;
    float: left;
  }
  .grid__item img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}